:root {
    --main-bg-button-primary: #ef984e;
    --main-color-button-primary: white;
    --header-color-text: white;
    --gradient-primary: #011e64;
    --gradient-secondary: #30a9d8;
    --component-header-option: #999;
}

@import './components/header.css';
@import './components/login.css';
@import './components/main.css';
@import './components/override.css';
@import './components/responsive.css';

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

input {
    font-family: 'Roboto', sans-serif;
}

a,
button {
    cursor: pointer;
}

.btn-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-orange {
    background-color: #ef984e;
    color: white;
}

/* FLEXBOX UTILS */

.d-flex {
    display: flex;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-start {
    justify-content: flex-start;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: end;
}

.align-items-start {
    align-items: flex-start;
}

.text-center {
    text-align: center;
}
